<template>
    <v-dialog
        v-model="isVisible"
        width="500"
        scrollable
        persistent
    >
      <v-card :loading="isLoading">
        <v-card-title>{{title}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 150px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  border="right"
                  dense
                  elevation="3"
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="model.fileUrl">
            <v-col
                cols="10"
            >
              <v-text-field
                label="Enlace de descarga"
                :value="model.fileUrl"
                readonly 
              ></v-text-field>
            </v-col>
          </v-row>
          
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
              color="blue-darken-1"
              text
              @click="download()"
              :loading="isSaving"
              :disabled="isLoading"
          >
            Descargar
          </v-btn>
          <v-btn
              color="blue-darken-1"
              text
              @click="close()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import versionVuelidate from '@vuelidate/core'
  //import {required} from '@vuelidate/validators'
  import versionService from "@/services/versions.service";
  
  
  export default {
    name: "VersionsDownloadDialog.vue",
    props:{
      dialog: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      return { v$: versionVuelidate() }
    },
    
    data() {
      return {
        title: "Descarga Nueva Versión",
        isSaving: false,
        isLoading: false,
        showError: false,
        message: "",
        model:{
          fileUrl: "",
          version: "",
        }
      };
    },
    computed:{
      isVisible(){
        return this.dialog;
      },
      fileErrors(){
        return this.v$.model.fileName.$errors.map(err => err.$message);
      },
      versionErrors(){
        return this.v$.model.version.$errors.map(err => err.$message);
      }
    },
    watch:{
      dialog(val){
        if(val){
          this.title = "Descargar Ultima Versión";

          versionService.lastAppVersion().then(d=>{
          this.model.fileUrl = d;
          }); 

          this.$emit("on-open", val);
        }else {
          this.$emit("on-close", val);  
        }
      }
    },
    mounted() {
    },
    methods:{ 
      close(){
        this.$emit("on-close", false);
      },
      download(){
          versionService.lastAppVersion().then(d=>{
            const link = document.createElement('a');
            link.href=d;
            link.download='UltimaVersion.apk';
            link.click();
          });
                  
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  