<template>
    <v-toolbar color="grey darken-3" height="80" flat>    
        <v-row>
          <v-col cols="8">
          </v-col>
          <v-col>
            <v-btn color="primary" class="float-right" @click="showLastVersion()" block>
              Descarga Versión Actual
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" class="float-right" @click="newVersion()" block>
              Nueva Versión
            </v-btn>
          </v-col>
        </v-row>
    </v-toolbar>
  </template>
  
  <script>

  export default {
    name: "VersionsTableToolbar",
    data:() =>({
      search: null,
    }),
    methods:{
      newVersion(){
         this.$emit("on-new-version", true);
      },
      showLastVersion(){
        this.$emit("on-download-version", true);
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  