<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div>
    <versions-new-dialog :dialog="newVersionDialog" @on-close="closedVersionNewDialog" @on-success="successVersionNewDialog"/>
    <versions-download-dialog :dialog="downloadVersionDialog" @on-close="closedVersionDownloadDialog" @on-success="successVersionDownloadDialog"/>
    <v-card class="border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="versions"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            class="table border-radius-xl"
            hide-default-footer
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <versions-table-toolbar @on-new-version="showNewVersionDialog" @on-download-version="showDownloadVersionDialog"/>
          </template>
          <template v-slot:item.id="{ item }">
            <div class="d-flex align-center">
              <span class="my-2 text-xs text-body font-weight-light">{{
                  item.id
                }}</span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }" class="d-flex">
            <v-icon
                small
                class="material-icons-round"
                @click="resendItem(item)"
            >
            {{ loadingResend ? 'refresh' : 'resend' }}
            </v-icon>
            <v-btn 
              icon
            >
              <v-icon
                small
                class="material-icons-round"
                @click="downloadItem(item)"
              >
                download
              </v-icon>
            </v-btn>
            <v-icon
                small
                class="material-icons-round"
                @click="deleteItem(item)"
            >
              delete
            </v-icon>
            
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col class="d-flex align-center" cols="6" lg="3">
            <span class="text-body me-3 text-sm">Elementos por página:</span>
            <v-select
                v-model="itemsPerPage"
                dense
                filled
                solo
                :items="[5,10,20,50]"
            ></v-select>            
          </v-col>
          <v-col class="ml-auto d-flex justify-end" cols="6">
            <v-pagination
                v-model="page"
                :length="pageCount"
                circle
                class="pagination"
                next-icon="fa fa-angle-right"
                prev-icon="fa fa-angle-left"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import versionService from "@/services/versions.service";

import VersionsTableToolbar from "@/views/Pages/Administration/Widgets/VersionsTableToolbar";
import VersionsNewDialog from "@/views/Pages/Administration/Widgets/VersionsNewDialog";
import VersionsDownloadDialog from "@/views/Pages/Administration/Widgets/VersionDownloadDialog";

export default {
  name: "VersionsTable",
  components:{
    VersionsTableToolbar,
    VersionsNewDialog,
    VersionsDownloadDialog,
  },
  data() {
    return {
      isLoading: false,
      loadingResend: false,
      newVersionDialog: false,
      downloadVersionDialog: false,
      link: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      versions: [],
      totalItems: 0,
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Id Archivo",
          value: "fileId",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Nombre",
          value: "file.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Versión",
          value: "version",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Fecha Creación",
          value: "file.creationTime",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  watch:{
    itemsPerPage(){
      this.getVersions();
    },
    page(){
      this.getVersions();
    }
  },
  mounted() {
    this.getVersions();
  },
  methods:{
    getVersions(){
      this.isLoading = true;
      const offset = (this.page - 1) * this.itemsPerPage;

      this.versions = [];
      
      versionService.getVersions(offset,this.itemsPerPage).then(d =>{
        this.pageCount = Math.ceil(d.totalCount / this.itemsPerPage);
        this.versions = d.items;
        this.totalItems = d.totalCount;
        this.isLoading = false;
      });
    },
    showNewVersionDialog(){
      this.newVersionDialog = true;
    },
    showDownloadVersionDialog(){
      this.downloadVersionDialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    closedVersionNewDialog(value){
      this.newVersionDialog = false;
      this.getVersions();
    },
    closedVersionDownloadDialog(){
      this.downloadVersionDialog = false;
      this.getVersions();
    },
    downloadItem(version){    
          versionService.createURLVersion(version.fileId).then((d) =>{
            this.getVersions();
            
            if(d!=""){
              window.open(d, '_blank');
              this.$swal('Versión descargada!', '', 'success');
            }else{
              this.$swal('Versión no descargada!', '', 'error');
            }
          })
        
    },
    deleteItem(version){
      this.$swal({
        title: `Estas seguro de eliminar las versión ${version.version}?`,
        text: "No es posible revertir esta acción!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {          
          versionService.deleteVersion(version.id).then(() =>{
            this.getVersions();
            this.$swal('Versión eliminada!', '', 'success');
          })
        } 
      });
    },
    resendItem(version){
      this.$swal({
        title: `Deseas Re-enviar la versión ${version.version}?`,
        text: "La versión seleccionada será re enviada",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviar',
        showLoaderOnConfirm: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        this.loadingResend=true;
        this.isLoading=true;
        if (result.isConfirmed) {          
          versionService.resendVersion(version.fileId).then(() =>{
            this.getVersions();
            this.$swal('Versión enviada!', '', 'success');
            this.loadingResend=false;
            this.isLoading=false;
          })
        } 
      });
    }
  }
}
</script>

<style scoped>

</style>
