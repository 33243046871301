/* eslint-disable no-dupe-keys */
import http, { e } from './http'
import loginService from './login.service'

const versionsService = {
    createVersion: function (data){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.post(e('app/app-version'), data, headers).then(r => {
          return r.data
        })
    },
    deleteVersion: function (versionId){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.remove(e(`app/app-version/${versionId}`), headers).then(r => {
          return r.data
        })
      },
      getVersions: function (data){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.get(e('app/app-version/versions'), data, headers).then(r => {
          return r.data
        })
      },
      uploadVersionFile: function (data){
        const token = loginService.getRawToken()
        let formData = new FormData()

        formData.append("file",data)
        
        const headers = {
          Authorization: 'Bearer ' + token,
          ContentType: 'multipart/form-data',
        };
        
        return http.post(e('app/file/apk'), formData, headers).then(r => {
          return r.data
        })
      },
      resendVersion: function (fileId){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.post(e(`app/app-version/${fileId}/resend`), headers).then(r => {
          return r.data
        })
      },
      createURLVersion: function (fileId){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.post(e(`app/app-version/${fileId}/link`), headers).then(r => {
          return r.data
        })
      },
      lastAppVersion(){
        const token = loginService.getRawToken()
    
        const headers = {
          Authorization: 'Bearer ' + token,
        };
    
        return http.get(e(`app/app-version/last-version`), null, headers).then(r => {
          return r.data
        })
      }
}

export default versionsService