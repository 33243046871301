<template>
    <v-dialog
        v-model="isVisible"
        width="600"
        scrollable
        persistent
    >
      <v-card :loading="isLoading">
        <v-card-title>{{title}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 250px;">
        <v-container>
          <v-row v-if="showError">
            <v-col>
              <v-alert
                  border="right"
                  dense
                  elevation="3"
                  type="error"
                  variant="outlined"
              >
                {{message}}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="10"
            >
              <v-file-input 
                multiple label="Archivo de Nueva Versión"
                required
                v-model="model.fileName">
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="10"
            >
              <v-text-field
                  label="Versión"
                  required
                  v-model="model.version" 
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
              color="blue-darken-1"
              text
              @click="save()"
              :loading="isSaving"
              :disabled="isLoading"
          >
          <template v-if="isSaving">
            <span>Guardando...</span>
            <v-progress-circular
              indeterminate
              size="20"
              width="2"
              color="white"
            ></v-progress-circular>
          </template>
          <template v-else>
            Guardar
          </template>
          </v-btn>
          <v-btn
              color="blue-darken-1"
              text
              @click="close()"
              :disabled="isSaving"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import versionVuelidate from '@vuelidate/core'
  //import {required} from '@vuelidate/validators'
  import versionService from "@/services/versions.service";
  
  
  export default {
    name: "VersionsNewDialog.vue",
    props:{
      dialog: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      return { v$: versionVuelidate() }
    },
    
    data() {
      return {
        title: "Nueva Versión",
        isSaving: false,
        isLoading: false,
        showError: false,
        message: "",
        model:{
          fileName: "",
          version: "",
        }
      };
    },
    computed:{
      isVisible(){
        return this.dialog;
      },
      fileErrors(){
        return this.v$.model.fileName.$errors.map(err => err.$message);
      },
      versionErrors(){
        return this.v$.model.version.$errors.map(err => err.$message);
      }
    },
    watch:{
      dialog(val){
        if(val){
          this.title = "Nueva Versión";
          this.$emit("on-open", val);
          this.clearForm()
        }else {
          this.$emit("on-close", val);  
        }
      }
    },
    mounted() {
    },
    methods:{ 
      close(){
        this.$emit("on-close", false);
        this.clearForm()
      },
      clearForm(){
        this.message = "";
        this.showError = false;
        this.model = {
                fileName: "",
                version: ""
        };
      },
      save(){
        const file = document.querySelector('input[type=file]').files[0]
        
        this.message = "";
        this.showError = false;
        
          if(file == undefined){
            this.showError = true;
            this.message = "Es obligatorio seleccionar un Archivo.";
            return
          }

          if(this.model.version == ""){
            this.showError = true;
            this.message = "Es necesario un consecutivo de Versión.";
            return
          }
          this.isSaving=true;
          versionService.uploadVersionFile(file).then(d=>{
                if(d){
                    const data = {
                    fileId: d.items[0].id,
                    version: this.model.version
                    }

                    return versionService.createVersion(data)
                }
            }).then(d=>{
                if(d)
                    this.clearForm()
                    this.isSaving=false;
                    this.$swal('Versión guardada!', '', 'success');
            })
        
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  