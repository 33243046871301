<template>
    <div>    
      <v-container class="py-6" fluid>
        <v-row>
          <v-col cols="12">
            <v-card class="card-shadow border-radius-xl">
              <versions-table ref="VersionsTable"></versions-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
    
    <script>
    
    import VersionsTable from "./Widgets/VersionsTable.vue";

    export default {
      name: "Versions",
      components: {
          VersionsTable
      },
      data() {
        return {
        };
      },  
      methods:{
      
      }
    }
    </script>
    
    <style scoped>
    
    </style>